import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import postPageJson from '../components/Post Page/.component.json';
import relatedPostsJson from '../components/RelatedPosts/.component.json';
import {
  blogAppDefId,
  blogCommentsAppDefId,
  blogCommentsWidgetId,
} from '../external/common/constants/apps';
import { TPA_PAGE_ID_POST } from '../external/common/constants/tpa-pages';
import concurrentEditing from '../external/common/services/concurrent-editing';
import { EditorAppContext } from '../types/editor-app-context.type';
import { addWidgetPlugin } from './add-widget-plugin';
import { updateBlogSettings } from './common/update-blog-settings';

const mapSlotWithWidgetId = {
  slotComments: {
    name: 'Comments',
    widgetId: blogCommentsWidgetId,
    appDefinitionId: blogCommentsAppDefId,
  },
  slotRelatedPosts: {
    name: 'Related Posts',
    widgetId: relatedPostsJson.id,
    appDefinitionId: blogAppDefId,
  },
} as const;

const successLog = (msg: string) =>
  console.log(msg, 'color: green; font-weight: bold;');

export const installNewPostPage = async (context: EditorAppContext) => {
  console.log('📝 [Blog] Installing New Post Page...');

  await concurrentEditing.withApproval(async () => {
    try {
      const postPageRef = await addNewPostPage(context);

      console.info('📝 [Blog]', { postPageRef });
      console.info('📝 [Blog] Installing New Post Page... Adding plugins...');

      const [widgetSlots] = await Promise.all([
        context.sdk.tpa.widgetPlugins.getWidgetSlots(context.appToken, {
          widgetRef: postPageRef,
        }),
        updateBlogSettings(context, { blocksPostPage: true }),
      ]);

      const isCommentsInstallationEnabled = context.flowAPI.experiments.enabled(
        EXPERIMENTS.ENABLE_COMMENTS_INSTALLATION,
      );

      for await (const slot of widgetSlots) {
        if (slot.role in mapSlotWithWidgetId) {
          const { widgetId, appDefinitionId, name } =
            mapSlotWithWidgetId[slot.role as keyof typeof mapSlotWithWidgetId];

          if (name === 'Comments' && !isCommentsInstallationEnabled) {
            return;
          }

          console.info(`📝 [Blog] Adding ${name} plugin...`);

          await addWidgetPlugin({
            widgetId,
            slotCompRef: slot.compRef,
            context,
            appDefinitionId,
          }).then(() => {
            successLog(`📝 [Blog] Adding ${name} plugin... %cDone!`);
          });
        }
      }

      successLog('%c📝 [Blog] Installing New Post Page... Done!');
    } catch (err) {
      console.error('📝 [Blog] Installing New Post Page... Failed!', err);
      throw err;
    }
  });
};

export async function addNewPostPage(context: EditorAppContext) {
  const widget = postPageJson;
  const rtl = context.flowAPI.environment.isSiteLanguageRTL;

  const appData = await context.sdk.tpa.app.getDataByAppDefId(
    context.appToken,
    blogAppDefId,
  );

  const pageRef = await context.sdk.pages.add(context.appToken, {
    title: 'Post',
    shouldAddMenuItem: true,
    shouldNavigateToPage: false,
    definition: {
      data: {
        tpaApplicationId: appData.applicationId,
        appDefinitionId: blogAppDefId,
        tpaPageId: TPA_PAGE_ID_POST,
        managingAppDefId: blogAppDefId,
        descriptionSEO: '',
        indexable: true,
        isLandingPage: false,
        isMobileLandingPage: false,
        isPopup: false,
        metaData: {
          isHidden: false,
          isPreset: false,
          pageId: 'masterPage',
        },
        metaKeywordsSEO: '',
        pageSecurity: {
          dialogLanguage: '',
          passwordDigest: '',
          requireLogin: false,
        },
        pageTitleSEO: '',
        pageUriSEO: 'post',
        translationData: {
          uriSEOTranslated: false,
        },
        type: 'Page',
        underConstruction: false,
      },
    },
  });

  const preset = rtl ? widget.defaultRtlPresetId : widget.defaultPresetId;
  const mobilePreset = rtl
    ? widget.defaultRtlMobilePresetId
    : widget.defaultMobilePresetId;

  const componentRef = await context.sdk.application.appStudioWidgets.addWidget(
    context.appToken,
    {
      installationType: 'closed',
      containerRef: { id: pageRef.id, type: 'DESKTOP' },
      presetIds: {
        layout: preset,
        style: preset,
      },
      scopedPresets: {
        desktop: {
          layout: preset,
          style: preset,
        },
        mobile: {
          layout: mobilePreset,
          style: mobilePreset,
        },
      },
      widgetId: widget.id,
      layout: {
        x: 0,
        y: 0,
        height: 600,
        // @ts-expect-error
        docked: {
          left: {
            px: 0,
            vw: 0,
          },
          right: {
            px: 0,
            vw: 0,
          },
        },
      },
      layouts: {
        componentLayout: {
          type: 'ComponentLayout',
          hidden: false,
          height: { type: 'auto' },
          minHeight: { type: 'px', value: 600 },
        },
        itemLayout: {
          id: '',
          justifySelf: 'stretch',
          alignSelf: 'stretch',
          type: 'GridItemLayout',
          gridArea: { rowStart: 1, rowEnd: 2, columnStart: 1, columnEnd: 2 },
          margins: {
            top: { type: 'px', value: 0 },
            left: { type: 'px', value: 0 },
          },
        },
        containerLayout: {
          type: 'GridContainerLayout',
          columns: [{ type: 'fr', value: 1 }],
          rows: [{ type: 'fr', value: 1 }],
        },
      },
    },
  );

  return componentRef;
}
